import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { editAdmin } from 'services/AdminServices'
import { getBrands } from 'services/BrandServices'
import { getEmployeeStatistics } from 'services/DashboardServices'
import { listOrderStatistics, productStatistics } from 'services/OrderServices'
import { getCredential, updateCompanyName, updateCredential } from 'services/ShiprocketServices'


export const post_CallingStatstics = createAsyncThunk('dashboard/post_CallingStatstics',async (data) => {
    const response = await getEmployeeStatistics(data)
    return response.data.employeeStats
})
export const patch_UpdateAdmin = createAsyncThunk('dashboard/patch_UpdateAdmin',async (data) => {
    const response = await editAdmin(data)
    return response.data
})
export const get_getCredential = createAsyncThunk('dashboard/get_getCredential',async (data) => {
    const response = await getCredential(data)
    return response.data
})
export const patch_updateCredential = createAsyncThunk('dashboard/patch_updateCredential',async (data) => {
    const response = await updateCredential(data)
    return response.data
})
export const patch_updateCompanyName = createAsyncThunk('dashboard/patch_updateCredential',async (data) => {
    const response = await updateCompanyName(data)
    return response.data
})
export const post_DispatchStatstics = createAsyncThunk('dashboard/post_DispatchStatstics',async (data) => {
    const response = await getEmployeeStatistics(data)
    return response.data.employeeStats
})
export const post_listOrderStatistics = createAsyncThunk('dashboard/post_listOrderStatistics',async (data) => {
    const response = await listOrderStatistics(data)
    return response?.data?.orderStats[0]
})
export const post_productStatistics = createAsyncThunk('dashboard/post_productStatistics',async (data) => {
    const response = await productStatistics(data)
    return response?.data?.productStatistics
})
export const get_Brands = createAsyncThunk(
    "dashboard/get_Brands",
    async (data) => {
      const response = await getBrands(data);
      const brands = await response?.data.brands.map((b) => ({
        value: b._id,
        label: b.name,
      }));
      return brands;
    }
  );


const dataSlice = createSlice({
    name: 'dashboard',
    initialState: {
        loading: false,
        updateloading: false,
        employeeList: [],
        brandList: [],
        callingStatisticList : [],
        dispatchStatisticList : [],
        productStatistics:[],
        orderStats : {},
        shiprocketCredential : {}
    },
    reducers: {
  
    },
    extraReducers: (builder) => {
        builder.addCase(get_Brands.fulfilled, (state, action) => {
            state.brandList = action.payload
            state.loading = false
        })
        builder.addCase(get_Brands.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(get_Brands.rejected, (state, action) => {
            state.loading = false
            state.brandList = []
        })
        builder.addCase(post_CallingStatstics.fulfilled, (state, action) => {
            state.callingStatisticList = action.payload
            state.loading = false
        })
        builder.addCase(post_CallingStatstics.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(post_CallingStatstics.rejected, (state, action) => {
            state.loading = false
            state.callingStatisticList = []
        })
        builder.addCase(post_DispatchStatstics.fulfilled, (state, action) => {
            state.dispatchStatisticList = action.payload
            state.loading = false
        })
        builder.addCase(post_DispatchStatstics.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(post_DispatchStatstics.rejected, (state, action) => {
            state.loading = false
            state.dispatchStatisticList = []
        })
        builder.addCase(post_listOrderStatistics.fulfilled, (state, action) => {
            state.orderStats = action.payload
            state.loading = false
        })
        builder.addCase(post_listOrderStatistics.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(post_listOrderStatistics.rejected, (state, action) => {
            state.loading = false
            state.orderStats = {}
        })
        builder.addCase(post_productStatistics.fulfilled, (state, action) => {
            state.productStatistics = action.payload
            state.loading = false
        })
        builder.addCase(post_productStatistics.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(post_productStatistics.rejected, (state, action) => {
            state.loading = false
            state.productStatistics = []
        })
        builder.addCase(get_getCredential.fulfilled, (state, action) => {
            state.shiprocketCredential = action.payload.credential
            state.loading = false
        })
        builder.addCase(get_getCredential.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(get_getCredential.rejected, (state, action) => {
            state.loading = false
            state.shiprocketCredential = []
        })
    }
})

// export const { 

// } = dataSlice.actions

export default dataSlice.reducer